<template>
  <div id="Case">
    <h2>四川省宣汉中学</h2>
    <!-- <p>Sichuan Xuanhan High School</p> -->
    <div class="case-box">
      <p>
        宣汉中学位于四川省达州市，是四川省重点中学、四川省一级示范性普通高中，学校前身可追溯至创办于明朝万历元年。学校自上而下高度重视校园安全工作。学校全面使用了平台功能。所有进出口及车辆道闸采用人脸闸机模式，让校园学生、教职工、外来人员及车辆信息，学校领导及相关工作部门和人员尽在掌握，让校园安全信息透明化、实时化。学校的重点安全区域，实施了智能预警系统，实现了重点区域的安全前置管理。宿舍管理升级为主动式人脸签到模式，配合宿舍可视化智慧屏及家校共育，提升了宿管工作人员的效率，降低了人工成本，让宿舍安全管理更加精细化。住校生归寝记录，附带学生抓拍照，实时推送给家长手机端，成为很多家长了解自己孩子每日在校情况的重要信息通道。学校在实践安全管理的过程中，充分体验了人情和温情，得到学生家长、区域主管单位和社会的一致好评。
      </p>
      <img src="../../../assets/images/partnersCase/cases/case8.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      margin: 50px 0;
    }
    .title {
      font-size: 32px;
      font-weight: bold;
      color: $--color-primary;
      text-align: center;
    }
    img {
      width: 70%;
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      img {
        width: 100%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;
    p {
      font-size: 14px;
      line-height: 28px;
      margin: 30px 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 40px;
    .title {
      font-size: 28px;
    }
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
